<template lang="html">
<el-dialog
  title="查看信息"
  :visible.sync="dialogVisible"
  width="50%">
  <div>
  	<el-descriptions title="基本信息">
		    <el-descriptions-item label="登录手机号">{{info.loginPhoneNumber}}</el-descriptions-item>
		    <el-descriptions-item label="注册设备">{{registerSourceOptions[info.registerSource]}}</el-descriptions-item>
		</el-descriptions>
		<el-descriptions title="认证信息" v-if="info.type==='company'">
		    <el-descriptions-item label="认证时间">{{info.createTime | dateTimeFormat}}</el-descriptions-item>
		    <el-descriptions-item label="企业名称">{{info.companyName}}</el-descriptions-item>
		    <el-descriptions-item label="成立日期">{{info.foundTime}}</el-descriptions-item>
		    <el-descriptions-item label="注册资本">{{info.registerCapital|changeToThree}} 元</el-descriptions-item>
		    <el-descriptions-item label="统一社会信用代码">{{info.unifiedSocialCode}}</el-descriptions-item>
		    <el-descriptions-item label="企业联系人">{{info.contactPersonName}}</el-descriptions-item>
		    <el-descriptions-item label="企业联系电话">{{info.contactPhoneNumber}}</el-descriptions-item>
		    <el-descriptions-item label="企业邮箱">{{info.email}}</el-descriptions-item>
		    <el-descriptions-item label="企业区域">{{provinceObj[info.provinceId]}}&nbsp;{{cityObj[info.cityId]}}&nbsp;{{areaObj[info.areaId]}}</el-descriptions-item>
		    <el-descriptions-item label="详细地址">{{info.detailAddress}}</el-descriptions-item>
		</el-descriptions>
    <el-descriptions title="认证信息" v-if="info.type==='lawyer'">
		    <el-descriptions-item label="认证时间">{{info.createTime | dateTimeFormat}}</el-descriptions-item>
        <el-descriptions-item label="认证主体">{{info.realName}}</el-descriptions-item>
		    <el-descriptions-item label="身份证号">{{info.idCardNumber}}</el-descriptions-item>
		    <el-descriptions-item label="联系手机">{{info.phoneNumber}}</el-descriptions-item>
		    <el-descriptions-item label="电子邮箱">{{info.email}}</el-descriptions-item>
		    <el-descriptions-item label="律所名称">{{info.lawFirmName}}</el-descriptions-item>
		    <el-descriptions-item label="首次执业时间">{{info.firstWorkTime}}</el-descriptions-item>
        <el-descriptions-item label="执业证号">{{info.licenceNumber}}</el-descriptions-item>
		    <el-descriptions-item label="详细地址">{{provinceObj[info.provinceId]}}&nbsp;{{cityObj[info.cityId]}}&nbsp;{{areaObj[info.areaId]}}</el-descriptions-item>
        <el-descriptions-item label="擅长领域">{{info.expertAreaName}}</el-descriptions-item>
		</el-descriptions>
    <el-descriptions title="" v-if="info.type==='lawyer'">
      <el-descriptions-item label="自我介绍">{{info.introduction}}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions title="认证信息" v-if="info.type==='user'">
		    <el-descriptions-item label="认证时间">{{info.createTime | dateTimeFormat}}</el-descriptions-item>
        <el-descriptions-item label="认证主体">{{info.realName}}</el-descriptions-item>
		    <el-descriptions-item label="身份证号">{{info.idCardNumber}}</el-descriptions-item>
		    <el-descriptions-item label="联系手机">{{info.phoneNumber}}</el-descriptions-item>
		    <el-descriptions-item label="电子邮箱">{{info.email}}</el-descriptions-item>
		    
		</el-descriptions>
  	<el-descriptions title="认证附件" direction="vertical" v-if="info.type==='company'">
		    <el-descriptions-item label="营业执照">
          <a :href="info.businessLicence" target="_blank">
            <img :src="info.businessLicence" style="width:88px;height:66px;"></img>
          </a>
        </el-descriptions-item>
		    <el-descriptions-item label="税务登记证">
          <a :href="info.taxRegistrationCertificate" target="_blank">
            <img :src="info.taxRegistrationCertificate" style="width:88px;height:66px;"></img>
          </a>
        </el-descriptions-item>
		</el-descriptions>
    <el-descriptions title="认证附件" direction="vertical" v-if="info.type==='user'">
		    <el-descriptions-item label="证件正面照">
          <a :href="info.idCardImgFront" target="_blank">
            <img :src="info.idCardImgFront" style="width:88px;height:66px;"></img>
          </a>
        </el-descriptions-item>
		    <el-descriptions-item label="证件反面照">
          <a :href="info.idCardImgBack" target="_blank">
            <img :src="info.idCardImgBack" style="width:88px;height:66px;"></img>
          </a>
        </el-descriptions-item>
		</el-descriptions>
    <el-descriptions title="认证附件" direction="vertical" v-if="info.type==='lawyer'">
		    <el-descriptions-item label="证件正面照">
          <a :href="info.idCardImgFront" target="_blank">
            <img :src="info.idCardImgFront" style="width:88px;height:66px;"></img>
          </a>
        </el-descriptions-item>
		    <el-descriptions-item label="证件反面照">
          <a :href="info.idCardImgBack" target="_blank">
            <img :src="info.idCardImgBack" style="width:88px;height:66px;"></img>
          </a>
        </el-descriptions-item>
        <el-descriptions-item label="律师执业证">
          <a :href="info.licenceImg" target="_blank">
            <img :src="info.licenceImg" style="width:88px;height:66px;"></img>
          </a>
        </el-descriptions-item>
        <el-descriptions-item label="执业形象照">
          <a :href="info.professionImg" target="_blank">
            <img :src="info.professionImg" style="width:88px;height:66px;"></img>
          </a>
        </el-descriptions-item>
		</el-descriptions>

		<div class="el-descriptions">
			<div class="el-descriptions__header">
				<div class="el-descriptions__title">审核操作</div>
			</div>
			
		</div>
		
		<el-form ref="form" :model="form" label-width="84px">
		  <el-radio-group v-model="form.value">
		      <el-radio label="1">通过审核</el-radio>
		      <el-radio label="2">未通过审核</el-radio>
		      <el-radio label="0">等待审核</el-radio>
		    </el-radio-group>
		  <el-form-item label="未通过原因" style="margin-top:12px;" v-show="form.value == '2'">
		    <el-input type="textarea" :rows="1" v-model="form.reason" style="width:300px;"></el-input>
		  </el-form-item>
    </el-form>

  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="cancel">关 闭</el-button>
    <el-button type="primary" @click="ensure">提交审核结果</el-button>
  </span>
</el-dialog>
</template>

<script>
import {approveAuthApply,rejectAuthApply} from '../../../api/verify.js'
import {mapState} from 'vuex'
    export default {
    	data(){
    		return{
    			dialogVisible:false,
    			form:{
    				value:'',
            reason:''
    			}
    		}
    	},
      computed:{
        ...mapState({
          provinceObj:state=>state.dictionary.provinceObj,
          cityObj:state=>state.dictionary.cityObj,
          areaObj:state=>state.dictionary.areaObj,
          registerSourceOptions:state=>state.dictionary.registerSourceOptions
        })
      },
      props:['info','type'],
    	components:{},
    	methods:{
    		cancel(){
    			this.dialogVisible = false
    		},
    		ensure(){
          let value = this.form.value;
          let para;
          if(value == 1){
            // 通过审核
            para = {
              applyId:this.info.id,
              type:this.info.type
            }
            approveAuthApply(para).then(res=>{
              let data = res.data;
              if(data.code ==='000'){
                this.$message.success(data.desc);
                this.$emit('complete');
              }else{
                this.$message.warning(data.desc);
              }
            })
            
          }else if(value ==2){
            // 未通过审核
            para = {
              applyId:this.info.id,
              type:this.info.type,
              reason:this.form.reason
            }
            rejectAuthApply(para).then(res=>{
              let data = res.data;
              if(data.code ==='000'){
                this.$message.success(data.desc);
                this.$emit('complete');
              }else{
                this.$message.warning(data.desc);
              }
            })
            
          }
    			this.dialogVisible = false;
    		}
    	}
    }
</script>

<style scoped lang="scss">
</style>