<!--  -->
<template>
  <div class="user-manage">
    <table-search :total="total" :conditions="conditions" @searchRes="searchRes" @refresh="refresh">
    </table-search>
    <div class="table">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        @filter-change="filterChange"
         @sort-change="sortChange"
        style="width: 100%">
        <el-table-column
          prop="accountId"
          sortable="custom"
          label="用户ID">
        </el-table-column>
        <el-table-column
          prop="identity"
          label="认证申请">
          <template slot-scope="scope">
            <span style="color:#409EFF;" v-show="scope.row.identity=='company'">认证企业申请</span>
            <span style="color:#F56C6C;" v-show="scope.row.identity=='lawyer'">认证律师申请</span>
            <span style="color:#67C23A;" v-show="scope.row.identity=='user'">认证个人申请</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="realName"
          width="250"
          label="认证主体">
          <template slot-scope="scope">
            <div>{{scope.row.realName}}</div>
              <div v-show="scope.row.identity=='user'">身份证号：{{scope.row.realSubject}}</div>
              <div v-show="scope.row.identity=='company'">信用代码：{{scope.row.realSubject}}</div>
              <div v-show="scope.row.identity=='lawyer'">执业证号：{{scope.row.realSubject}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="userStatus"
          :filters="statusFilter"
          column-key="userStatus"
          label="状态">
          <template slot-scope="scope">
            <status-field :status="scope.row.userStatus"></status-field>
          </template>
        </el-table-column>
        <el-table-column
          prop="applyStatus"
          label="审核状态">
          <template slot-scope="scope">
            <span>{{verifyStatus[scope.row.applyStatus]}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          width="240"
          label="注册信息">
          <template slot-scope="scope">
            <div>注册手机：{{scope.row.phoneNumber}}</div>
              <div>注册时间：{{scope.row.userCreateTime}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="applyTime"
          sortable="custom"
          label="认证提交时间">
        </el-table-column>
        <el-table-column
          width="160"
          prop="val"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="checkHandle(scope.row)">用户动态</el-button>
              <el-divider direction="vertical" v-if="scope.row.applyStatus==0"></el-divider>
              <el-button type="text" @click="userHandle(scope.row)" v-if="scope.row.applyStatus==0">进行审核</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer flex-row">
      <div class="page">
        <el-pagination
          background
          :page-size="form.perPage"
          @size-change="handleSizeChange"
          :current-page="form.page"
          layout="prev, pager, next,sizes,jumper"
          :page-sizes="[10, 20, 30, 40]"
          @current-change="currentChange"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <user-dynamic ref="userDynamic"></user-dynamic>
    <user-verify-dialog ref="userVerifyDialog" :info="info" @complete="refresh"></user-verify-dialog>
  </div>
</template>

<script>
import TableSearch from '../../components/table-search.vue'
import UserDynamic from '../../components/dialog/user-dynamic.vue'
import UserVerifyDialog from './coms/user-verify-dialog.vue'
import {applyList,applyDetail} from '../../api/verify.js'
import StatusField from '../../components/table/status-field.vue'
export default {
  name:'userVerify',
  data () {
    return {
      loading:false,
      info:{},
      form:{
        perPage:10,
        page:1,
        userStatus:'',
        orderBy:'applyTimeDesc'
      },
      total:0,
      verifyStatus:{
        "0":"审核中",
        "1":"审核通过",
        "2":"审核拒绝"
      },
      conditions:[
        {value:'phoneNumber',label:'注册手机'},
        {value:'accountId',label:'用户ID'},
        {value:'realName',label:'认证主体名字'},
        {value:'realSubject',label:'认证主体证件号'}
      ],
      tableData:[],
      multipleSelection:[],
      statusFilter:[
        {text: '正常', value: 0}, 
        {text: '禁言', value: 1}, 
        {text: '禁止登陆', value: 2}
      ],
        applyFilter:[
          {text: '认证企业申请', value: '1'}, 
          {text: '认证律师申请', value: '2'}, 
          {text: '认证律所申请', value: '3'},
          {text: '认证个人申请', value: '4'}
        ]

    };
  },
  components: {
    TableSearch,
    UserDynamic,
    UserVerifyDialog,
    StatusField
  },
  computed: {
    
  },
  methods: {
    getDetail(row){
      let para={
        applyId:row.applyId,
        type:row.identity
      }
      applyDetail(para).then(res=>{
        let data = res.data;
        if(data.code==='000'){
          this.info = data.content;
          this.info.type = row.identity;
          this.info.loginPhoneNumber = row.phoneNumber;
          this.info.registerSource = row.registerSource;
          this.info.province = this.$getProvinceName(this.info.provinceId);
          this.info.city = this.$getCityName(this.info.cityId);
          this.info.area = this.$getAreaName(this.info.areaId);
        }else{
          console.log(data.desc)
        }
      })
    },
    refresh(){
      this.getList();
    },
    searchRes(para){
      this.form = Object.assign({
        page:1,
        perPage:this.form.perPage,
        userStatus:this.form.userStatus
        },para);
      this.getList();
    },
    getList(){
      this.loading = true;
      applyList(this.form).then(res=>{
        let data = res.data;
        this.loading = false;
        this.tableData = data.content.list;
        this.total = data.content.total;
      })
    },
    sortChange(sort){
      if(sort.prop =='applyTime'){
        this.form.orderBy = sort.order==='ascending'?'applyTimeAsc':'applyTimeDesc';
      }
      if(sort.prop =="accountId"){
        this.form.orderBy = sort.order==='ascending'?'accountIdAsc':'accountIdDesc';
      }
      this.getList();
    },
    // 分页
    currentChange(val){
      this.form.page = val;
      this.getList();
    },
    handleSizeChange(val){
      this.form.perPage = val;
      this.form.page =1;
      this.getList();
    },
    addHandle(){},
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val)
    },
    checkHandle(row){
      this.$refs.userDynamic.dialogVisible = true;
    },
    userHandle(row){
      this.getDetail(row);
      this.$refs.userVerifyDialog.dialogVisible = true;
    },
    editHandle(row){},
    forbidAll(){},
    forbid(){},
    filterChange(filters){
      for(let key in filters){
        this.form[key] = filters[key].join();
      }
      this.form.page = 1;
      this.getList();
    },
  },
  created(){
    this.getList();
  }
}

</script>
<style lang='scss' scoped>
.footer{
  justify-content: flex-end;
  margin:15px 0;
}
.dot{
  font-size: 20px;
  padding-right: 5px;
  vertical-align:middle;
}
</style>